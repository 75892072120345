<template>
    <div class="layout-page">
        <div class="summary-container">
            <div class="summary-info">
                <div class="bills-generated">
                    <div class="title">{{ $t('customers.clients') }}</div>
                    <div class="number" v-if="items">{{ Object.values(items).length }}</div>
                </div>
                <div class="bills-generated">
                    <div class="title">{{ $t('bills.import') }}</div>
                    <div class="number">{{ importMoney + '€' }}</div>
                </div>
            </div>
            <div class="summary-actions">
                <FilterCustomers v-on:applyFilters="resetSelectedItems()" v-on:resetFilters="resetSelectedItems()"></FilterCustomers>
                <div class="table-actions">
                    <div class="export">
                        <div class="export-button" @click="exportButton = !exportButton">{{ selected.length > 0 ? $t('bills.export') : $t('bills.export_all') }}</div>
                        <transition name="fade">
                            <div class="export-box" v-if="exportButton">
                                <div class="text" @click="downloadPDF()">{{ $t('bills.export_as_pdf') }}</div>
                                <div class="text" @click="downloadEXCEL()">{{ $t('bills.export_as_xlsx') }}</div>
                                <div class="text" @click="downloadXML()">{{ $t('bills.export_as_xml') }}</div>
                            </div>
                        </transition>
                    </div>
                    <div class="search">
                        <input type="text" v-model="search" class="input-search" name="name" />
                    </div>
                </div>
            </div>
        </div>
        <div class="data-table">
            <v-data-table @toggle-select-all="allSelected = !allSelected" v-if="items" v-model="selected" :headers="headers" fixed-header :search="search" :items="Object.values(items)" sort-by="name" :single-select="singleSelect" :sort-asc="true" hide-default-footer show-select :page.sync="pagination.page" :items-per-page="pagination.itemsPerPage" @page-count="pageCount = $event">
                <template #item.customer_cif="item">
                    <div class="ticket">{{ item.item.customer_cif }}</div>
                </template>
                <template #item.name="item">
                    <div class="row">{{ item.item.name }}</div>
                </template>
                <template #item.import="item">
                    <div class="row">{{ parseFloat(item.item.import).toFixed(2) + '€' }}</div>
                </template>
                <template #item.bills="item">
                    <div class="row">{{ item.item.bills }}</div>
                </template>
                <template #item.id="item">
                    <div class="row-image">
                        <img class="icon" @click="exportPDF(item.item.customer_cif)" src="../../public/img/pdf_icon.svg" />
                    </div>
                </template>
                <template v-slot:no-data>
                    <div class="no-data">
                        {{ $t('bills.table_no_data') }}
                    </div>
                </template>
            </v-data-table>
            <div id="pagination">
                <div class="totalItems">
                    <span class="total">
                        <strong class="total-results">{{ Object.values(items).length }}</strong>
                        {{ $tc('bills.results', Object.values(items).length) }}
                    </span>
                </div>
                <v-pagination v-model="pagination.page" color="#C4C4C4" :length="pageCount" :total-visible="maxPageVisibles"></v-pagination>
                <div class="rows-page">
                    <v-select :items="rowsPerPage" :label="nRegistros" v-model="pagination.itemsPerPage"></v-select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FilterCustomers from '../components/layout/admin/customers/Filter.vue'
export default {
    components: { FilterCustomers },
    name: 'Customers',
    data() {
        return {
            search: '',
            pagination: {
                page: 1,
                itemsPerPage: 20
            },
            allSelected: false,
            exportButton: false,
            singleSelect: false,
            valueFilter: 1,
            rowsPerPage: [10, 20, 50],
            pageCount: 0,
            selected: [],
            maxPageVisibles: 10
        }
    },
    computed: {
        items() {
            var items = this.$store.getters['customers/getCustomers']()
            return items
        },
        headers() {
            return [
                {
                    text: this.$t('customers.fiscal_id'),
                    align: 'start',
                    sortable: true,
                    value: 'customer_cif',
                    width: 'auto',
                    class: 'header-table'
                },
                {
                    text: this.$t('customers.name'),
                    value: 'name',
                    width: 'auto',
                    sortable: true,
                    class: 'header-table'
                },
                {
                    text: this.$t('customers.total_import'),
                    value: 'import',
                    align: 'start',
                    width: 'auto',
                    sortable: true,
                    class: 'header-table'
                },
                {
                    text: this.$t('customers.bill'),
                    value: 'bills',
                    align: 'start',
                    width: 'auto',
                    sortable: true,
                    class: 'header-table'
                },
                {
                    text: '',
                    value: 'id',
                    align: 'start',
                    width: '40px',
                    sortable: true,
                    class: 'header-table'
                }
            ]
        },
        nRegistros() {
            var letter = this.$t('bills.show_bills')
            var nRegisters = this.pagination.itemsPerPage
            letter = letter.toString().replace(' ', ' ' + nRegisters + ' ')
            return letter
        },
        pages() {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null) {
                return 0
            }

            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        },
        importMoney() {
            var number = 0
            if (Object.values(this.items).length > 0) {
                Object.values(this.items).forEach(function (numero) {
                    number += numero.import
                })
            }
            return parseFloat(number).toFixed(2)
        },
        customers() {
            var tempResult = {}
            if (this.items) {
                var arr = Object.values(this.items)

                for (let { customer_fiscal_id } of arr)
                    tempResult[customer_fiscal_id] = {
                        customer_fiscal_id,
                        count: tempResult[customer_fiscal_id] ? tempResult[customer_fiscal_id].count + 1 : 1
                    }

                let result = Object.values(tempResult)

                return result
            }
            return tempResult
        }
    },
    methods: {
        load() {
            this.$store.dispatch('customers/loadFiltersCustomers').then(function () {})
        },
        downloadPDF() {
            var parameters = {}
            const filtersActive = this.$store.getters['customers/getCustomersFiltersActive']

            if (Object.values(this.selected) == 0 || this.allSelected) {
                parameters.all = 1
            } else {
                var ids = []
                for (var bill in Object.values(this.selected)) {
                    var idx = Object.values(this.selected)[bill]
                    if (idx) {
                        ids.push(idx.customer_cif)
                    }
                }
                parameters.customers = ids.toString()
            }

            if (filtersActive.customers != 'admin.filters.all_customers') {
                parameters.customers = filtersActive.customers
            }

            if (typeof filtersActive.date !== 'undefined') {
                parameters.date = filtersActive.date
            }

            if (typeof filtersActive.start_date !== 'undefined') {
                parameters.start_date = filtersActive.start_date
            }

            if (typeof filtersActive.end_date !== 'undefined') {
                parameters.end_date = filtersActive.end_date
            }

            this.$overlay.show()

            const self = this
            this.$store.dispatch('customers/downloadPDF', parameters).then(function (response) {
                self.exportButton = false
                self.$overlay.hide()
                window.open(response, 'blank')
            })
        },
        exportPDF(id) {
            const customer = this.$store.getters['customers/getCustomers'](id)
            const dateExport = this.$store.getters['customers/getCustomersFiltersActive'].date

            const params = {
                customers: customer.customer_cif,
                all: 0
            }

            if (typeof dateExport !== 'undefined') {
                params.date = dateExport
            }

            this.$overlay.show()

            const self = this
            this.$store.dispatch('customers/downloadPDF', params).then(function (response) {
                self.$overlay.hide()
                window.open(response, 'blank')
            })
        },
        downloadEXCEL() {
            var parameters = {}
            const filtersActive = this.$store.getters['customers/getCustomersFiltersActive']

            if (Object.values(this.selected) == 0 || this.allSelected) {
                parameters.all = 1
            } else {
                var ids = []
                for (var bill in Object.values(this.selected)) {
                    var idx = Object.values(this.selected)[bill]
                    if (idx) {
                        ids.push(idx.customer_cif)
                    }
                }
                parameters.customers = ids.toString()
            }

            if (filtersActive.customers != 'admin.filters.all_customers') {
                parameters.customers = filtersActive.customers
            }

            if (typeof filtersActive.date !== 'undefined') {
                parameters.date = filtersActive.date
            }

            if (typeof filtersActive.start_date !== 'undefined') {
                parameters.start_date = filtersActive.start_date
            }

            if (typeof filtersActive.end_date !== 'undefined') {
                parameters.end_date = filtersActive.end_date
            }

            this.$overlay.show()

            const self = this
            this.$store.dispatch('customers/downloadEXCEL', parameters).then(function (response) {
                self.exportButton = false
                self.$overlay.hide()
                window.open(response, 'blank')
            })
        },
        downloadXML() {
            const parameters = {}
            const filtersActive = this.$store.getters['customers/getCustomersFiltersActive']

            if (Object.values(this.selected) == 0 || this.allSelected) {
                parameters.all = 1
            } else {
                const ids = []

                for (let bill in Object.values(this.selected)) {
                    const idx = Object.values(this.selected)[bill]

                    if (idx) {
                        ids.push(idx.customer_cif)
                    }
                }

                parameters.customers = ids.toString()
            }

            if (filtersActive.customers != 'admin.filters.all_customers') {
                parameters.customers = filtersActive.customers
            }

            if (typeof filtersActive.date !== 'undefined') {
                parameters.date = filtersActive.date
            }

            if (typeof filtersActive.start_date !== 'undefined') {
                parameters.start_date = filtersActive.start_date
            }

            if (typeof filtersActive.end_date !== 'undefined') {
                parameters.end_date = filtersActive.end_date
            }
            this.$overlay.show()

            const self = this
            this.$store.dispatch('customers/downloadXML', parameters).then((response) => {
                self.exportButton = false
                self.$overlay.hide()
                window.open(response, 'blank')
            })
        },
        resetSelectedItems() {
            this.selected = []
            this.exportButton = false
        }
    },
    created() {
        this.load()
    }
}
</script>

<style lang="scss">
.search {
    .input-search {
        background-image: url('../../public/img/searcher.svg');
    }
}
</style>
